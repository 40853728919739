<template>
    <div class="component" :class="classes">
        <dn-article class="subcomponent subcomponent--content subcomponent--center" data-spacing-bottom="xxxl">
            <h2 v-if="header">{{ header }}</h2>
            <template v-if="linkBlock">
                <navlink
                    :type="mapType(linkBlock.__typename)"
                    :target="mapTarget(linkBlock.targetBlank)"
                    :url="mapURI(linkBlock)"
                    classes="button button--primary"
                    :icon="linkBlock.icon"
                >
                    {{ linkBlock.titel }}
                </navlink>
            </template>
        </dn-article>
        <slider-continuous :items="projectsOrPortfolios" />
    </div>
</template>

<script>
import { get } from 'lodash';
import { mapState } from 'vuex';
import SliderContinuous from '~/patterns/organisms/slider-continuous/slider-continuous.vue';
import Article from '~/patterns/organisms/article/article.vue';
import Navlink from '~/patterns/atoms/navlink/navlink';
import Navigation from '~/mixins/navigation';

export default {
    components: {
        SliderContinuous,
        Navlink,
        'dn-article': Article
    },
    mixins: [
        Navigation
    ],
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return { cases: [] }; }
        }
    },
    computed: {
        projectsOrPortfolios() {
            return get(this, 'content.projectsOrPortfolios', []);
        },
        header() {
            return get(this, 'content.header', null);
        },
        linkBlock() {
            return get(this, 'content.linkBlock.0', null);
        },
        classes() {
            const background = get(this, 'content.background', 'color-lightest');
            if (background) {
                return `background background--bleed background--${background}`;
            }
            return 'background background--bleed background--color-lightest';
        },
        ...mapState('route', ['params'])
    }
};
</script>
