<template>
    <div :class="classes">
        <faq :questions="content.faq" />
    </div>
</template>

<script>
import { get } from 'lodash';
import Faq from '~/patterns/molecules/faq/faq';

export default {
    components: {
        Faq
    },
    props: {
        content: {
            type: Object,
            required: true,
            default: () => {
                return {
                    faq: []
                };
            }
        }
    },
    computed: {
        classes() {
            const background = get(this, 'content.background', 'color-lightest');
            if (background) {
                return `background background--bleed background--${background}`;
            }
            return 'background background--bleed background--color-lightest';
        }
    }
};
</script>
