<template>
    <navlink
        v-if="content"
        :type="mapType(linkBlock.__typename)"
        :target="mapTarget(linkBlock.targetBlank)"
        :url="mapURI(linkBlock)"
        :icon="linkBlock.icon"
        class="link-card"
    >
        <div class="link-card__image">
            <figure v-if="imageSrc">
                <img :src="imageSrc" :alt="imageAlt" :title="imageTitle" :loading="loading">
            </figure>
        </div>

        <h4 class="link-card__title"><icon v-if="simple"><icon-external /></icon>{{ header }}</h4>

        <html-content v-if="content.wysiwyg && !simple">
            <dn-article>
                <div v-html="content.wysiwyg" /> <!-- eslint-disable-line vue/no-v-html -->
            </dn-article>
        </html-content>
    </navlink>
</template>

<script>
import { get } from 'lodash';
import Navlink from '~/patterns/atoms/navlink/navlink';
import Navigation from '~/mixins/navigation';
import HtmlContent from '~/patterns/atoms/html-content/html-content';
import Article from '~/patterns/organisms/article/article';
import Icon from '~/patterns/atoms/icon/icon.vue';
import IconExternal from '~/patterns/atoms/_icons/external.vue';

export default {
    components: {
        Navlink,
        HtmlContent,
        'dn-article': Article,
        Icon,
        IconExternal
    },
    mixins: [
        Navigation
    ],
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return {}; }
        },
        loading: {
            type: String,
            default: 'lazy'
        },
        simple: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data() {
        return {};
    },
    computed: {
        header() {
            return this.content.header;
        },
        imageSrc() {
            return get(this, 'image.url', '');
        },
        imageAlt() {
            return get(this, 'image.alt', '');
        },
        imageTitle() {
            return get(this, 'image.title', '');
        },
        image() {
            return get(this, 'content.image.0', {});
        },
        linkBlock() {
            return get(this, 'content.linkUrl.0', {});
        },
        wysiwyg() {
            return this.content.wysiwyg;
        }
    }
};
</script>

<style src="./link-card.less" lang="less" />
