<template>
    <div class="component component--collection-large news-block" :class="classes">
        <h3 class="news-block__title" data-spacing-bottom="xxl">
            <span v-if="header">{{ header }}</span>
            <span v-else>{{ $t('news.title') }}</span>
            <nuxt-link v-if="!hideMoreNews" :to="localePath({ name: 'nieuws-slug' })" class="news-block__more button button--tertiary button--icon-right">
                {{ $t('news.more_news') }}
                <span class="button__icon">
                    <icon><icon-chevron-right-large /></icon>
                </span>
            </nuxt-link>
        </h3>
        <dn-article v-if="wysiwyg" class="subcomponent subcomponent--content" data-spacing-bottom="xxxl">
            <div v-html="wysiwyg" /> <!-- eslint-disable-line vue/no-v-html -->
        </dn-article>

        <collection-news v-if="content" :cards="news" @open-video="openVideo" />
        <modal-popup v-if="videoUrl" class="modal-popup--video animated fadeIn fast" @close="closeVideo">
            <template v-slot:image>
                <embed-video :url="videoUrl" :caption="videoCaption" />
            </template>
        </modal-popup>
    </div>
</template>

<script>
import { get } from 'lodash';
import Icon from '~/patterns/atoms/icon/icon.vue';
import IconChevronRightLarge from '~/patterns/atoms/_icons/chevron-right-large.vue';
import CollectionNews from '~/patterns/organisms/_collections/collection-news/collection-news.vue';
import Article from '~/patterns/organisms/article/article.vue';
import ModalPopup from '~/patterns/molecules/modal-popup/modal-popup.vue';
import EmbedVideo from '~/patterns/atoms/embed-video/embed-video';

export default {
    components: {
        CollectionNews,
        Icon,
        IconChevronRightLarge,
        'dn-article': Article,
        ModalPopup,
        EmbedVideo
    },
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return { news: [] }; }
        }
    },
    data() {
        return {
            video: null
        };
    },
    computed: {
        news() {
            return get(this, 'content.news', []);
        },
        header() {
            return get(this, 'content.header', null);
        },
        wysiwyg() {
            return get(this, 'content.wysiwyg', null);
        },
        hideMoreNews() {
            return get(this, 'content.hideMoreNews', false);
        },
        videoUrl() {
            return get(this, 'video.simpleUrl', '');
        },
        videoCaption() {
            return get(this, 'video.caption', '');
        },
        classes() {
            const background = get(this, 'content.background', 'color-lightest');
            if (background) {
                return `background background--bleed background--${background}`;
            }
            return 'background background--bleed background--color-lightest';
        }
    },
    methods: {
        openVideo(item) {
            this.video = item;
        },
        closeVideo() {
            if (this.video) {
                this.video = null;
                history.back();
            }
        }
    }
};
</script>

<style lang="less" src="./news-block.less" />
