<template>
    <item-grid
        class="item-grid--mb"
        data-columns-xs="1"
        data-columns-sm="1"
        data-columns-md="1"
        data-columns-lg="2"
        data-columns-xl="2"
    >
        <div v-for="(image, index) in images" :key="index" class="item-grid__item">
            <figure v-if="image.url" class="subcomponent subcomponent--content">
                <img :src="image.url" :alt="image.alt" :title="image.title" :loading="loading">
            </figure>
        </div>
    </item-grid>
</template>

<script>
import ItemGrid from '~/patterns/molecules/item-grid/item-grid.vue';

export default {
    components: {
        ItemGrid
    },
    props: {
        images: {
            type: Array,
            required: true,
            default: () => {
                return [];
            }
        },
        loading: {
            type: String,
            default: 'lazy'
        }
    }
};
</script>
