<template>
    <div :class="classes">
        <figure v-if="src" class="subcomponent subcomponent--content">
            <img :src="src" :alt="alt" :title="title" :loading="loading">
            <figcaption v-if="caption">{{ caption }}</figcaption>
        </figure>
    </div>
</template>

<script>
import { get } from 'lodash';

export default {
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return {}; }
        },
        loading: {
            type: String,
            default: 'lazy'
        }
    },
    computed: {
        src() {
            return get(this, 'content.fullwidthimage_main.0.url', '');
        },
        alt() {
            return get(this, 'content.fullwidthimage_main.0.alt', '');
        },
        title() {
            return get(this, 'content.fullwidthimage_main.0.title', '');
        },
        caption() {
            return get(this, 'content.caption', '');
        },
        classes() {
            const background = get(this, 'content.background', 'color-lightest');
            if (background) {
                return `background background--bleed background--${background}`;
            }
            return 'background background--bleed background--color-lightest';
        }
    }
};
</script>
