<template>
    <navlink v-if="url" :url="url" class="card">
        <div class="card__image">
            <figure v-if="imageObject && imageSrc">
                <img :src="imageSrc" :alt="imageAlt" :title="imageTitle" :loading="loading">
            </figure>
            <icon-block v-if="programSlug || themeSlug" :name="programSlug || themeSlug" class="card__image__icon" />
        </div>

        <h4 v-if="header" class="card__title">{{ header }}</h4>

        <html-content v-if="wysiwyg">
            <dn-article>
                <div v-html="wysiwyg" /> <!-- eslint-disable-line vue/no-v-html -->
            </dn-article>
        </html-content>
    </navlink>
</template>

<script>
import { get } from 'lodash';
import Navlink from '~/patterns/atoms/navlink/navlink';
import Navigation from '~/mixins/navigation';
import HtmlContent from '~/patterns/atoms/html-content/html-content';
import Article from '~/patterns/organisms/article/article';
import IconBlock from '~/patterns/atoms/icon-block/icon-block.vue';

export default {
    components: {
        Navlink,
        HtmlContent,
        'dn-article': Article,
        IconBlock
    },
    mixins: [
        Navigation
    ],
    props: {
        image: {
            type: Object,
            default: () => {}
        },
        header: {
            type: String,
            default: ''
        },
        url: {
            type: String,
            required: true
        },
        wysiwyg: {
            type: String,
            default: ''
        },
        programCategory: {
            type: Object,
            default: () => {}
        },
        loading: {
            type: String,
            default: 'lazy'
        }
    },
    data() {
        return {};
    },
    computed: {
        imageSrc() {
            return get(this, 'imageObject.url', '');
        },
        imageAlt() {
            return get(this, 'imageObject.alt', '');
        },
        imageTitle() {
            return get(this, 'imageObject.title', '');
        },
        imageObject() {
            return get(this, 'image', '');
        },
        theme() {
            return get(this, 'programCategory.themeCategory[0]', '');
        },
        themeSlug() {
            return get(this, 'theme.slug', '');
        },
        programSlug() {
            return get(this, 'programCategory.slug', '');
        }
    }
};
</script>

<style src="./card.less" lang="less" />
