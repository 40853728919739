<template>
    <div
        v-if="content"
        class="component component--collection"
        :class="classes"
    >
        <item-grid
            class="item-grid--mb"
            data-columns-xs="1"
            data-columns-sm="2"
            data-columns-md="3"
            data-columns-lg="3"
            data-columns-xl="3"
        >
            <link-card v-for="(card, index) in cards" :key="index" :content="card" class="item-grid__item" :simple="simpleLayout" />
        </item-grid>
    </div>
</template>

<script>
import { get } from 'lodash';
import LinkCard from '~/patterns/organisms/flexible-section/blocktypes/link-card.vue';
import ItemGrid from '~/patterns/molecules/item-grid/item-grid.vue';

export default {
    components: {
        LinkCard,
        ItemGrid
    },
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return { cards: [] }; }
        }
    },
    computed: {
        cards() {
            return get(this, 'content.cards', []);
        },
        layout() {
            return get(this, 'content.layout', '');
        },
        simpleLayout() {
            return this.layout === 'simple';
        },
        classes() {
            const background = get(this, 'content.background', 'color-lightest');
            const layout = this.simpleLayout ? 'component--link-cards--layout-simple' : 'component--link-cards--layout-cards';
            return `background background--bleed background--${background} ${layout}`;
        },
    }
};
</script>

<style lang="less" scoped>
    .component--link-cards--layout-simple .item-grid {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
    }
</style>
