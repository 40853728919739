<template>
    <div class="component component--collection cases-block" :class="classes">
        <dn-article v-if="wysiwyg" class="subcomponent subcomponent--content" data-spacing-bottom="xxxl">
            <h3 v-if="header">{{ header }}</h3>
            <div v-html="wysiwyg" /> <!-- eslint-disable-line vue/no-v-html -->
        </dn-article>
        <collection-cases v-if="content && cases" :cases="cases" />
    </div>
</template>

<script>

import { get } from 'lodash';
import { mapState } from 'vuex';
import CollectionCases from '~/patterns/organisms/_collections/collection-cases/collection-cases.vue';
import Article from '~/patterns/organisms/article/article.vue';

export default {
    components: {
        CollectionCases,
        'dn-article': Article
    },
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return { cases: [] }; }
        },
        loading: {
            type: String,
            required: false,
            default: 'lazy'
        }
    },
    computed: {
        cases() {
            return get(this, 'content.cases', []);
        },
        header() {
            return get(this, 'content.header', null);
        },
        wysiwyg() {
            return get(this, 'content.wysiwyg', null);
        },
        classes() {
            const background = get(this, 'content.background', 'color-lightest');
            if (background) {
                return `background background--bleed background--${background}`;
            }
            return 'background background--bleed background--color-lightest';
        },
        ...mapState('route', ['params'])
    }
};
</script>
