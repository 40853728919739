<template>
    <div v-if="name && icon" class="icon-block" :class="icon.class">
        <icon-select :icon="icon.component" />
    </div>
</template>

<script>
import IconSelect from '~/patterns/atoms/icon-select/icon-select.vue';

export default {
    components: {
        IconSelect
    },
    props: {
        name: {
            type: String,
            required: true,
            default: ''
        }
    },
    computed: {
        icon() {
            switch (this.name) {
            case 'green':
            case 'groen':
                return {
                    class: 'icon-block--green',
                    component: 'icon-theme-green'
                };
            case 'social':
            case 'sociaal':
                return {
                    class: 'icon-block--social',
                    component: 'icon-theme-social'
                };
            case 'creative':
            case 'creatief':
                return {
                    class: 'icon-block--creative',
                    component: 'icon-theme-creative'
                };
            case 'circular-business':
            case 'circulair-ondernemen':
                return {
                    class: 'icon-block--green',
                    component: 'icon-program-circular-business'
                };
            case 'sustainable-energy':
            case 'duurzame-energie':
                return {
                    class: 'icon-block--green',
                    component: 'icon-program-sustainable-energy'
                };
            case 'culture-and-media':
            case 'cultuur-en-media':
                return {
                    class: 'icon-block--creative',
                    component: 'icon-program-culture-and-media'
                };
            case 'sustainable-food-system':
            case 'duurzaam-voedselsysteem':
                return {
                    class: 'icon-block--green',
                    component: 'icon-program-sustainable-food-system'
                };
            case 'social-management-and-inclusion':
            case 'sociaal-ondernemen-en-inclusie':
                return {
                    class: 'icon-block--social',
                    component: 'icon-program-social-management-and-inclusion'
                };
            case 'innovative-local-initiatives':
            case 'vernieuwende-bewonersinitiatieven':
                return {
                    class: 'icon-block--social',
                    component: 'icon-program-innovative-local-initiatives'
                };
            case 'bankgiro-loterij-fonds':
                return {
                    class: 'icon-block--creative',
                    component: 'icon-program-bankgiro-loterij-fonds'
                };
            }
            return null;
        }
    }
};
</script>

<style src="./icon-block.less" lang="less" />
