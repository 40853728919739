<template>
    <div class="component component--collection" :class="classes">
        <dn-article v-if="wysiwyg" class="subcomponent subcomponent--content subcomponent--center" data-spacing-bottom="xxl">
            <div v-html="wysiwyg" /> <!-- eslint-disable-line vue/no-v-html -->
        </dn-article>
        <collection-images :images="images" />
    </div>
</template>

<script>
import { get } from 'lodash';
import CollectionImages from '~/patterns/organisms/_collections/collection-images/collection-images.vue';
import Article from '~/patterns/organisms/article/article.vue';

export default {
    components: {
        CollectionImages,
        'dn-article': Article
    },
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return {}; }
        },
        loading: {
            type: String,
            default: 'lazy'
        }
    },
    computed: {
        wysiwyg() {
            return get(this, 'content.wysiwyg', null);
        },
        images() {
            return get(this, 'content.images', null);
        },
        classes() {
            const background = get(this, 'content.background', 'color-lightest');
            if (background) {
                return `background background--bleed background--${background}`;
            }
            return 'background background--bleed background--color-lightest';
        }
    }
};
</script>
