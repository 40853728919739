<template>
    <div v-if="content" class="component component--collection" :class="classes">
        <h2 v-if="header" class="subcomponent subcomponent--content subcomponent--center" data-spacing-bottom="xxl">{{ header }}</h2>
        <item-grid
            v-if="!showAsSlider"
            class="item-grid--mb"
            data-columns-xs="2"
            data-columns-sm="3"
            data-columns-md="3"
            data-columns-lg="4"
            data-columns-xl="4"
        >
            <team-member v-for="(teammember, index) in team" :key="index" :content="teammember" class="item-grid__item" />
        </item-grid>
        <slider-continuous v-else :items="team" data-spacing-bottom="xxl" />
    </div>
</template>

<script>
import { get } from 'lodash';
import TeamMember from '~/patterns/organisms/flexible-section/blocktypes/teammember.vue';
import ItemGrid from '~/patterns/molecules/item-grid/item-grid.vue';
import SliderContinuous from '~/patterns/organisms/slider-continuous/slider-continuous.vue';

export default {
    components: {
        TeamMember,
        ItemGrid,
        SliderContinuous
    },

    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return { team: [] }; }
        }
    },

    computed: {
        team() {
            return get(this, 'content.team', []);
        },
        header() {
            return get(this, 'content.header', null);
        },
        showAsSlider() {
            return get(this, 'content.showAsSlider', null);
        },
        classes() {
            const background = get(this, 'content.background', 'color-lightest');
            if (background) {
                return `background background--bleed background--${background}`;
            }
            return 'background background--bleed background--color-lightest';
        }
    }
};
</script>
