<template>
    <div :class="classes">
        <div class="subcomponent subcomponent--content">
            <embed-video :url="url" :caption="caption" />
        </div>
    </div>
</template>

<script>
import { get } from 'lodash';
import EmbedVideo from '~/patterns/atoms/embed-video/embed-video';

export default {
    components: {
        EmbedVideo
    },
    props: {
        content: {
            type: Object,
            required: true,
            default: () => {
                return {};
            }
        }
    },
    computed: {
        url() {
            return this.content.url;
        },

        caption() {
            return this.content.caption;
        },
        classes() {
            const background = get(this, 'content.background', 'color-lightest');
            if (background) {
                return `background background--bleed background--${background}`;
            }
            return 'background background--bleed background--color-lightest';
        }
    }
};

</script>
