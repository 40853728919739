<template>
    <div v-if="themeCategory" class="component component--collection programmes-block" :class="classes">
        <dn-article>
            <h3>
                <icon-block v-if="isDoen && themeSlug" :name="themeSlug" class="programmes-block__icon" />
                <span class="programmes-block__title">{{ themeTitle }}</span>
            </h3>
            <html-content>
                <div v-html="content.wysiwyg" /> <!-- eslint-disable-line vue/no-v-html -->
            </html-content>
        </dn-article>
        <item-grid
            class="item-grid--mb"
            data-spacing-top="xxxl"
            data-columns-xs="1"
            data-columns-sm="2"
            data-columns-md="3"
            data-columns-lg="3"
            data-columns-xl="3"
        >
            <card
                v-for="programCategory in programCategories"
                :key="programCategory.id"
                :url="localePath({ name: 'wat-we-doen-program', params: { program: programCategory.slug } })"
                :header="programCategory.title"
                :wysiwyg="programCategory.wysiwyg"
                :image="programCategory.image[0]"
                :program-category="programCategory"
                class="item-grid__item programmes-block__card"
            />
        </item-grid>
    </div>
</template>

<script>

import { get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import HtmlContent from '~/patterns/atoms/html-content/html-content';
import Article from '~/patterns/organisms/article/article';
import Card from '~/patterns/molecules/_cards/card/card.vue';
import ItemGrid from '~/patterns/molecules/item-grid/item-grid.vue';
import IconBlock from '~/patterns/atoms/icon-block/icon-block.vue';

export default {
    components: {
        HtmlContent,
        'dn-article': Article,
        ItemGrid,
        Card,
        IconBlock
    },
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return { themeCategory: [], wyswiyg: '' }; }
        }
    },
    async fetch() {
        await this.getByThemes({ themes: this.themeCategoryIds });
    },
    computed: {
        themeSlug() {
            return get(this, 'themeCategory[0].slug', '');
        },
        themeTitle() {
            return get(this, 'themeCategory[0].title', '');
        },
        themeCategory() {
            return get(this, 'content.themeCategory', []);
        },
        themeCategoryIds() {
            return this.themeCategory.map(v => v.id);
        },
        programCategories() {
            return this.relatedProgramCategories(this.themeCategoryIds);
        },
        wysiwyg() {
            return this.content.wysiwyg;
        },
        classes() {
            const background = get(this, 'content.background', 'color-lightest');
            if (background) {
                return `background background--bleed background--${background}`;
            }
            return 'background background--bleed background--color-lightest';
        },
        ...mapGetters('programcategories', {
            relatedProgramCategories: 'getByThemes'
        }),
        ...mapGetters('sites', {
            isDoen: 'isDoen'
        })
    },
    methods: {
        ...mapActions('programcategories', ['getByThemes'])
    }
};
</script>

<style lang="less" src="./programmes-block.less" />
