<template>
    <div v-if="imageSrc" class="card-simple">
        <div class="card-simple__image">
            <figure>
                <img :src="imageSrc" :alt="title" :title="title" :loading="loading">
            </figure>
        </div>
    </div>
</template>

<script>
import { get } from 'lodash';

export default {
    props: {
        image: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: ''
        },
        loading: {
            type: String,
            default: 'lazy'
        }
    },

    computed: {
        imageSrc() {
            return get(this, 'image.0.url', '');
        }
    }
};
</script>

<style src="./card-simple.less" lang="less" />
