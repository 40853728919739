<template>
    <div class="faq">
        <div
            v-for="(question, i) in questions"
            :key="`faq-item${i}`"
            :data-active="open === i"
            class="faq__item"
        >
            <button class="faq__question" @click="toggleQuestion(i)">
                <h4>
                    {{ question.question }}
                </h4>

                <icon>
                    <DnIconAdd />
                </icon>
            </button>

            <div v-show="open === i" class="faq__answer" v-html="question.answer" /> <!-- eslint-disable-line -->
        </div>
    </div>
</template>

<script>
'use strict';

import Icon from '~/patterns/atoms/icon/icon.vue';
import DnIconAdd from '~/patterns/atoms/_icons/add.vue';

export default {
    components: {
        Icon,
        DnIconAdd
    },
    props: {
        questions: {
            type: Array,
            required: true,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            open: null
        };
    },
    methods: {
        toggleQuestion(questionNumber) {
            if (this.open === questionNumber) {
                this.open = null;
            } else {
                this.open = questionNumber;
            }
        }
    }
};
</script>

<style lang="less" src="./faq.less"></style>
