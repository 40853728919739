<template>
    <div class="component component--spacious" :class="classes">
        <prominent-text
            v-if="header"
            :header="header"
            :show-lotteries="showLotteries"
            class="prominent-text"
        />
    </div>
</template>

<script>
import { get } from 'lodash';
import ProminentText from '~/patterns/molecules/prominent-text/prominent-text.vue';

export default {
    components: {
        ProminentText
    },
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return {}; }
        }
    },
    computed: {
        header() {
            return get(this, 'content.header', null);
        },
        showLotteries() {
            return get(this, 'content.showLotteries', null);
        },
        classes() {
            const background = get(this, 'content.background', 'color-lightest');
            if (background) {
                return `background background--bleed background--${background}`;
            }
            return 'background background--bleed background--color-lightest';
        }
    }
};
</script>
