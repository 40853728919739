<template>
    <div>
        <template v-for="(section, key) in sections">
            <component
                :is="map[section.__typename]"
                v-if="section.__typename"
                :key="key"
                :content="section"
                class="component"
                :class="{ 'component--spacious' : spacious }"
            />
        </template>
    </div>
</template>

<script>
import Wysiwyg from './blocktypes/wysiwyg.vue';
import FullWidthImage from './blocktypes/full-width-image.vue';
import Faq from './blocktypes/faq.vue';
import Video from './blocktypes/video.vue';
import LinkBlock from './blocktypes/linkBlock.vue';
import TextImage from './blocktypes/text-image.vue';
import LinkCards from './blocktypes/link-cards.vue';
import TeamBlock from './blocktypes/team-block.vue';
import NewsBlock from './blocktypes/news-block.vue';
import ProgrammesBlock from './blocktypes/programmes-block.vue';
import CasesBlock from './blocktypes/cases-block.vue';
import ProminentText from './blocktypes/prominent-text-block.vue';
import ImagesBlock from './blocktypes/images-block.vue';
import ProjectsOrPortfolios from './blocktypes/projects-or-portfolios.vue';

const graphToComponentMapping = {
    flexibleSection_wysiwyg_BlockType: Wysiwyg,
    flexibleSection_fullwidthimage_BlockType: FullWidthImage,
    flexibleSection_faq_BlockType: Faq,
    flexibleSection_video_BlockType: Video,
    flexibleSection_textImage_BlockType: TextImage,
    flexibleSection_linkCards_BlockType: LinkCards,
    flexibleSection_linkBlock_BlockType: LinkBlock,
    flexibleSection_teamBlock_BlockType: TeamBlock,
    flexibleSection_newsBlock_BlockType: NewsBlock,
    flexibleSection_programmes_BlockType: ProgrammesBlock,
    flexibleSection_cases_BlockType: CasesBlock,
    flexibleSection_prominentText_BlockType: ProminentText,
    flexibleSection_images_BlockType: ImagesBlock,
    flexibleSection_projectsOrPortfolios_BlockType: ProjectsOrPortfolios
};

export default {
    props: {
        sections: {
            type: Array,
            required: false,
            default: () => { return []; }
        },
        spacious: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        map: () => graphToComponentMapping
    }
};
</script>
