<template>
    <div v-if="content" class="component component--teammember-block team-member">
        <div class="team-member__image">
            <figure v-if="photoSrc">
                <img :src="photoSrc" :alt="photoAlt" :title="photoTitle" :loading="loading">
            </figure>
        </div>
        <h4 class="team-member__name">{{ memberTitle }}</h4>
        <p class="team-member__function">{{ memberFunction }}</p>
    </div>
</template>

<script>
import { get } from 'lodash';

export default {
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return { team: [] }; }
        },
        loading: {
            type: String,
            default: 'lazy'
        }
    },
    computed: {
        photo() {
            return get(this, 'content.photo.0', {});
        },
        photoSrc() {
            return get(this, 'photo.url', '');
        },
        photoAlt() {
            return get(this, 'photo.alt', '');
        },
        photoTitle() {
            return get(this, 'photo.title', '');
        },
        memberTitle() {
            return get(this, 'content.title', '');
        },
        memberFunction() {
            return get(this, 'content.function', '');
        }
    }
};
</script>

<style src="./team-member.less" lang="less" />
