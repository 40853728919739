<template>
    <div class="slider-continuous">
        <div
            class="slider-continuous__track"
            :style="animationTiming"
        >
            <div v-for="n in amountOfLoops" :key="n" class="slider-continuous__content">
                <div v-for="(item, index) in filteredItems" :key="index" class="slider-continuous__content__slide">
                    <card-simple :image="getImage(item)" :title="item.title" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { get } from 'lodash';
import CardSimple from '~/patterns/molecules/_cards/card-simple/card-simple.vue';

export default {
    components: {
        CardSimple
    },

    props: {
        items: {
            type: Array,
            required: true,
            default: () => {
                return [];
            }
        }
    },

    data() {
        return {
            minimumItems: 8 * 2, // Do not change unless you also change the css breakpoints
            secondsPerItem: 2 // Do not change unless you know what you are doing
        };
    },

    computed: {
        filteredItems() {
            if (this.items && this.items.length) {
                return this.items.filter(item =>
                    (item.cardImage && item.cardImage.length) ||
                    (item.featuredImage && item.featuredImage.length) ||
                    (item.photo && item.photo.length)
                );
            }
            return [];
        },
        // Amount of loops has to be divisible by two or the loop animation will get cut up.
        amountOfLoops() {
            // The minimum amount of items has to be 16 because of the css breakpoints.
            // The max amount of items shown on the screen is 8.
            // Therefore tha amount of loops has to be the minimum amount of items (16) / the amount of items, rounded up
            let minimumLoops = Math.ceil(this.minimumItems / this.filteredItems.length);

            // If the result of this calculation is not divisible by two we need to add it once more, so that it will be divisible by two
            if (minimumLoops > 2) {
                minimumLoops = minimumLoops % 2 === 0 ? minimumLoops : minimumLoops + 1;
            }
            return Math.max(2, minimumLoops);
        },
        // Change the animation timing based on the amount of items
        animationTiming() {
            const realAmountOfItems = this.amountOfLoops * this.filteredItems.length; // Amount of items actually on the page
            const secondsTiming = realAmountOfItems * this.secondsPerItem; // Amount of seconds needed
            return `animation-duration: ${secondsTiming}s`;
        }
    },

    methods: {
        getImage(item) {
            return get(item, 'cardImage',
                get(item, 'featuredImage',
                    get(item, 'photo', null)
                )
            );
        }
    }
};
</script>

<style lang="less" src="./slider-continuous.less" />
