// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/img/backgrounds/doen-news-placeholder.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/img/backgrounds/bglf-news-placeholder.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../../assets/img/backgrounds/participaties-news-placeholder.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--dn-input_padding-x:0.637rem;--dn-input_padding-y:1rem;--dn-input_border-radius:2px;--dn-input_border:1px solid #c6d4dd;--spacing-y:0.637rem;--spacing-x:1rem}.link--clean,body a.link--clean{background:none;border-color:transparent;color:inherit;-webkit-text-decoration:none;text-decoration:none}.link--clean:active,.link--clean:focus,.link--clean:hover,.link--clean:visited,body a.link--clean:active,body a.link--clean:focus,body a.link--clean:hover,body a.link--clean:visited{background:none;border-color:transparent;color:#000;color:initial;-webkit-text-decoration:none;text-decoration:none}.card-simple__image{background-position:50%;background-repeat:no-repeat;background-size:auto 44%;position:relative}body .card-simple__image,body[data-theme=theme--doen] .card-simple__image{background-color:#e6f6ff}body[data-theme=theme--bglf] .card-simple__image{background-color:#7392bb}body[data-theme=theme--participaties] .card-simple__image{background-color:#868fa8}body .card-simple__image,body[data-theme=theme--doen] .card-simple__image{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}body[data-theme=theme--bglf] .card-simple__image{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}body[data-theme=theme--participaties] .card-simple__image{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}body[data-theme=theme--bglf] .card-simple__image{background-size:auto 2.2rem}.card-simple__image:after{content:\"\";display:block;height:0;padding-bottom:100%;width:100%}.card-simple__image figure{height:100%;position:absolute;width:100%}.card-simple__image img{box-shadow:8px 8px 0 rgba(0,0,0,.12);height:100%;-o-object-fit:cover;object-fit:cover;overflow:hidden;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
