<template>
    <dn-article v-if="header" class="prominent-text">
        <h2 class="prominent-text__title">{{ header }}</h2>

        <div v-if="showLotteries" class="prominent-text__lotteries">
            <span class="prominent-text__lotteries__1">
                <img src="~/assets/img/logos/logo-vriendenloterij.png" alt="VriendenLoterij">
            </span>
            <span class="prominent-text__lotteries__2">
                <img src="~/assets/img/logos/logo-postcodeloterij.png" alt="Nationale Postcode Loterij">
            </span>
        </div>
    </dn-article>
</template>

<script>
import Article from '~/patterns/organisms/article/article.vue';

export default {
    components: {
        'dn-article': Article
    },
    props: {
        header: {
            type: String,
            required: true,
            default: ''
        },
        showLotteries: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="less" src="./prominent-text.less"></style>
