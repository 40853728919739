<template>
    <item-grid
        class="item-grid--mb"
        data-columns-xs="1"
        data-columns-sm="2"
        data-columns-md="3"
        data-columns-lg="3"
        data-columns-xl="3"
    >
        <card
            v-for="projectCase in cases"
            :key="projectCase.id"
            :url="localePath({ name: 'wat-we-doen-program-slug', params: { program: getProgramSlug(projectCase), slug: projectCase.slug } })"
            :header="projectCase.title"
            :wysiwyg="projectCase.cardWysiwyg"
            :image="projectCase.cardImage[0]"
            :program-category="projectCase.programCategory[0]"
            class="item-grid__item"
        />
    </item-grid>
</template>

<script>

import { get } from 'lodash';

import Card from '~/patterns/molecules/_cards/card/card.vue';
import ItemGrid from '~/patterns/molecules/item-grid/item-grid.vue';

export default {
    components: {
        ItemGrid,
        Card
    },
    props: {
        cases: {
            type: Array,
            required: true,
            default: () => { return []; }
        }
    },
    methods: {
        getProgramSlug(projectCase) {
            return get(projectCase, 'programCategory.0.slug', '');
        }
    }
};
</script>
