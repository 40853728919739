<template>
    <transition name="modal-popup">
        <div class="modal-popup" :class="{ 'modal-popup--image': hasImage }">
            <div class="modal-popup__wrapper">
                <div v-on-clickaway="away" class="modal-popup__container">
                    <button class="modal-popup__close" @click="close"><icon><icon-cross /></icon></button>
                    <div v-if="hasImage" class="modal-popup__image">
                        <slot name="image" class="modal-popup__image" />
                    </div>
                    <div v-if="hasDefault || hasFooter" class="modal-popup__content article">
                        <slot />
                        <div v-if="hasFooter" class="modal-popup__footer">
                            <slot name="footer" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapActions } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import Icon from '~/patterns/atoms/icon/icon';
import IconCross from '~/patterns/atoms/_icons/cross.vue';

export default {
    components: {
        Icon,
        IconCross
    },
    mixins: [clickaway],
    computed: {
        hasImage() {
            return !!this.$slots.image;
        },
        hasDefault() {
            return !!this.$slots.default;
        },
        hasFooter() {
            return !!this.$slots.footer;
        }
    },
    mounted() {
        this.activate();
    },
    destroyed() {
        this.deactivate();
    },
    methods: {
        close() {
            this.$emit('close');
        },
        away() {
            this.$emit('close');
        },
        ...mapActions('modal', {
            activate: 'activate',
            deactivate: 'deactivate'
        })
    }
};
</script>

<style src="./modal-popup.less" lang="less" />
