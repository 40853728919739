<template>
    <div class="link-block" :class="classes">
        <h3 v-if="content.header" class="link-block__title">{{ content.header }}</h3>
        <div v-for="(link, index) in content.linkBlock" :key="index">
            <navlink
                :type="mapType(link.__typename)"
                :url="mapURI(link)"
                :target="mapTarget(link.targetBlank)"
                class="link-block__link"
                :classes="content.looksLikeButton ? 'button button--primary button--icon-right' : 'link-block__link'"
                :icon="link.icon"
            >
                {{ link.titel }}
            </navlink>
        </div>
    </div>
</template>

<script>
import { get } from 'lodash';
import Navlink from '~/patterns/atoms/navlink/navlink';
import Navigation from '~/mixins/navigation';

export default {
    components: {
        Navlink
    },
    mixins: [
        Navigation
    ],
    props: {
        content: {
            type: Object,
            required: true,
            default: () => {
                return {
                    header: '',
                    linkBlock: []
                };
            }
        }
    },
    computed: {
        classes() {
            const background = get(this, 'content.background', 'color-lightest');
            if (background) {
                return `background background--bleed background--${background}`;
            }
            return 'background background--bleed background--color-lightest';
        }
    }
};
</script>

<style src="./link-block.less" lang="less" />
