<template>
    <item-grid
        class="item-grid--mb-2x"
        data-columns-xs="1"
        data-columns-sm="1"
        data-columns-md="3"
        data-columns-lg="3"
        data-columns-xl="3"
    >
        <div v-for="(card, index) in cards" :key="index" class="item-grid__item">
            <card-news :card="card" @open-video="openVideo" />
        </div>
    </item-grid>
</template>

<script>
import ItemGrid from '~/patterns/molecules/item-grid/item-grid.vue';
import CardNews from '~/patterns/molecules/_cards/card-news/card-news.vue';

export default {
    components: {
        ItemGrid,
        CardNews
    },
    props: {
        cards: {
            type: Array,
            required: true,
            default: () => {
                return [];
            }
        }
    },
    methods: {
        openVideo(item) {
            this.$emit('open-video', item);
        }
    }
};
</script>
