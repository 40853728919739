<template>
    <html-content :class="classes">
        <dn-article class="subcomponent subcomponent--content">
            <div v-html="content.wysiwyg_main" /> <!-- eslint-disable-line vue/no-v-html -->
        </dn-article>
    </html-content>
</template>

<script>
import { get } from 'lodash';
import HtmlContent from '~/patterns/atoms/html-content/html-content.vue';
import Article from '~/patterns/organisms/article/article.vue';

export default {
    components: {
        HtmlContent,
        'dn-article': Article
    },
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return {}; }
        }
    },
    computed: {
        classes() {
            const background = get(this, 'content.background', 'color-lightest');
            if (background) {
                return `background background--bleed background--${background}`;
            }
            return 'background background--bleed background--color-lightest';
        }
    }
};
</script>
