<template>
    <div v-if="content" class="component component--collection text-image" :class="classes">
        <item-grid
            class="item-grid--mb"
            data-columns-xs="1"
            data-columns-sm="1"
            data-columns-md="2"
            data-columns-lg="2"
            data-columns-xl="2"
        >
            <figure v-if="src && leftRight === ALIGNED_LEFT" class="item-grid__item">
                <img :src="src" :alt="alt" :title="title" :loading="loading">
                <figcaption v-if="caption">{{ caption }}</figcaption>
            </figure>
            <html-content class="item-grid__item">
                <dn-article>
                    <div v-html="content.wysiwyg" /> <!-- eslint-disable-line vue/no-v-html -->
                </dn-article>

                <template v-if="linkBlock">
                    <navlink
                        :type="mapType(linkBlock.__typename)"
                        :target="mapTarget(linkBlock.targetBlank)"
                        :url="mapURI(linkBlock)"
                        :icon="linkBlock.icon"
                        classes="button button--primary"
                        data-spacing-top="xxl"
                        data-spacing-bottom="md"
                    >
                        {{ linkBlock.titel }}
                    </navlink>
                </template>
            </html-content>
            <div class="item-grid__item">
                <figure v-if="src && leftRight !== ALIGNED_LEFT">
                    <img :src="src" :alt="alt" :title="title" :loading="loading">
                    <figcaption v-if="caption">{{ caption }}</figcaption>
                </figure>
            </div>
        </item-grid>
    </div>
</template>

<script>
import { get } from 'lodash';
import HtmlContent from '~/patterns/atoms/html-content/html-content';
import Article from '~/patterns/organisms/article/article';
import Navlink from '~/patterns/atoms/navlink/navlink';
import Navigation from '~/mixins/navigation';
import ItemGrid from '~/patterns/molecules/item-grid/item-grid.vue';

export const ALIGNED_RIGHT = false;
export const ALIGNED_LEFT = true;

export default {
    components: {
        Navlink,
        HtmlContent,
        'dn-article': Article,
        ItemGrid
    },
    mixins: [
        Navigation
    ],
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return {}; }
        },
        loading: {
            type: String,
            default: 'lazy'
        }
    },
    data() {
        return {
            ALIGNED_LEFT,
            ALIGNED_RIGHT
        };
    },
    computed: {
        src() {
            return get(this, 'content.image.0.url', '');
        },
        alt() {
            return get(this, 'content.image.0.alt', '');
        },
        title() {
            return get(this, 'content.image.0.title', '');
        },
        caption() {
            return this.content.caption;
        },
        leftRight() {
            return this.content.leftRight;
        },
        wysiwyg() {
            return this.content.wysiwyg;
        },
        linkBlock() {
            return get(this, 'content.linkBlock.0', null);
        },
        classes() {
            const background = get(this, 'content.background', 'color-lightest');
            let classes = '';

            if (background) {
                classes += `background background--bleed background--${background}`;
            } else {
                classes += 'background background--bleed background--color-lightest';
            }

            if (this.src) {
                if (this.leftRight === ALIGNED_LEFT) {
                    classes += ' text-image--image-left';
                }
                classes += ' text-image--image-right';
            }
            return classes;
        },
    }
};
</script>

<style src="./text-image.less" lang="less" />
