<template>
    <nuxt-link
        v-if="!isVideo"
        :to="url"
        class="card-news"
    >
        <div class="card-news__image">
            <figure v-if="imageSrc">
                <img :src="imageSrc" :alt="imageAlt" :title="imageTitle" :loading="loading">
            </figure>
        </div>

        <div class="card-news__info">
            <span v-if="card.postDate" class="card-news__info__date">{{ card.postDate | localisedDate }}</span>
            <span v-if="category" class="card-news__info__category">{{ category }}</span>
        </div>
        <h4 v-if="title" class="card-news__title">{{ title }}</h4>
    </nuxt-link>
    <a
        v-else
        :href="url"
        class="card-news card-news--video"
        @click.stop.prevent.capture="onItemClick"
    >
        <div class="card-news__image">
            <figure v-if="imageSrc">
                <img :src="imageSrc" :alt="imageAlt" :title="imageTitle" :loading="loading">
            </figure>
        </div>

        <div class="card-news__info">
            <span v-if="card.postDate" class="card-news__info__date">{{ card.postDate | localisedDate }}</span>
            <span v-if="category" class="card-news__info__category">{{ category }}</span>
        </div>
        <h4 v-if="title" class="card-news__title">{{ title }}</h4>
    </a>
</template>

<script>
import { get } from 'lodash';
import Navigation from '~/mixins/navigation';

export default {
    mixins: [
        Navigation
    ],
    props: {
        card: {
            type: Object,
            required: true,
            default: () => { return {}; }
        },
        loading: {
            type: String,
            default: 'lazy'
        }
    },
    computed: {
        title() {
            return this.card.title;
        },
        category() {
            return get(this, 'card.newsCategory.0.title', '');
        },
        imageSrc() {
            return get(this, 'image.url', '');
        },
        imageAlt() {
            return get(this, 'image.alt', '');
        },
        imageTitle() {
            return get(this, 'image.title', '');
        },
        image() {
            return get(this, 'card.cardImage.0', get(this, 'card.featuredImage.0', {}));
        },
        url() {
            return this.localePath({ name: 'nieuws-slug', params: { slug: this.card.slug } });
        },
        isVideo() {
            return get(this, 'card.__typename') === 'news_video_Entry';
        }
    },
    methods: {
        /**
         * Hijack the click event on the item link, so we can change the url and open the modal ourselves. This way
         * we stay on this page and algolia doesn't need to reload and mess with the url history. But the full url
         * works the same, the modal will be loaded with the search / index on the background.
         */
        onItemClick() {
            const resolved = this.$router.resolve(
                this.url,
                this.$route,
                false
            );

            history.pushState(
                {},
                this.title,
                resolved.href
            );

            this.$emit('open-video', this.card);
        }
    }
};
</script>

<style src="./card-news.less" lang="less" />
