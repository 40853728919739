<template>
    <figure v-if="service" class="embed-video">
        <div class="embed-video__aspect-ratio">
            <LoadingSpinner class="embed-video__loading-spinner" />
            <template v-if="service === SERVICE_YOUTUBE">
                <iframe
                    class="embed-video__item"
                    :src="`https://www.youtube-nocookie.com/embed/${id}?modestbranding=1&controls=1&rel=0${autoplaySettings}`"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    :loading="loading"
                />
            </template>

            <template v-if="service === SERVICE_VIMEO">
                <iframe
                    class="embed-video__item"
                    :src="`https://player.vimeo.com/video/${id}?byline=0&portrait=0&color=ffffff&dnt=1&pip=0&responsive=1&title=0&byline=0&transparent=0${autoplaySettings}`"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                    :loading="loading"
                />
            </template>
        </div>

        <figcaption v-if="caption">{{ caption }}</figcaption>
    </figure>
</template>

<script>
import LoadingSpinner from '~/patterns/atoms/loading-spinner/loading-spinner';

const SERVICE_YOUTUBE = 'youtube',
    SERVICE_VIMEO = 'vimeo',
    YTREGEX = /^(?:(?:https?:)?\/\/)?(?:(?:www|m)\.)?(?:youtube\.com|youtu.be)(?:\/(?:[\w]+\?v=|embed\/|v\/)?)([\w-]+)(?:\S+)?$/i,
    VIMEOREGEX = /^https:\/\/vimeo\.com\/([0-9]+)$/i;

export default {
    components: {
        LoadingSpinner
    },
    props: {
        url: {
            type: String,
            required: true,
            default: ''
        },
        caption: {
            type: String,
            default: ''
        },
        loading: {
            type: String,
            default: 'lazy'
        },
        autoplay: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            SERVICE_VIMEO,
            SERVICE_YOUTUBE
        };
    },
    computed: {
        service() {
            return this.parsed.service;
        },
        id() {
            return this.parsed.id;
        },
        parsed() {
            return parseUrl(this.url);
        },
        autoplaySettings() {
            return this.autoplay ? '&autoplay=1' : '';
        }
    }
};

function parseUrl(url) {
    const service = parseService(url);

    return {
        service,
        id: parseId(service, url)
    };
}

function parseService(url) {
    if (url.match(YTREGEX)) {
        return SERVICE_YOUTUBE;
    }

    if (url.match(VIMEOREGEX)) {
        return SERVICE_VIMEO;
    }

    return null;
}

function parseId(service, url) {
    switch (service) {
    case SERVICE_YOUTUBE: {
        const matches = url.match(YTREGEX);
        return matches[1];
    }
    case SERVICE_VIMEO: {
        const matches = url.match(VIMEOREGEX);
        return matches[1];
    }
    }

    return null;
}
</script>

<style lang="less" src="./embed-video.less"></style>
